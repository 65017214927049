import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DialogCloseButton from '../dialog_buttons/DialogCloseButton';
import SignaturePad from 'signature_pad';
import Rails from '@rails/ujs';
import {notify} from 'react-notify-toast';
import $ from 'jquery';

export default class SignatureDialog extends PureComponent {
    static dataURLtoBlob = dataURL => {
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});
    };

    constructor(props) {
        super(props);
        this.canvas = null;
        this.pad = null;
        this.state = {fullName: '', latitude: null, loading: false, longitude: null};
    }

    componentDidMount() {
        $(window).resize(this.resizeCanvas);
        $(document).on('open.zf.reveal', () => setTimeout(this.resizeCanvas, 100));
        $(document).on('closed.zf.reveal', () => this.pad.clear());
        $('[data-react-class="dialogs/SignatureDialog"]').removeAttr('data-react-class');
        this.canvas = document.querySelector('canvas');
        this.pad = new SignaturePad(this.canvas);
    }

    componentWillUnmount() {
        $(document).off('open.zf.reveal', this.resizeCanvas);
        $(window).off('resize', this.resizeCanvas);
    }

    getMyLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.recordPosition, this.handleGeolocationError,
                {timeout: 30000, maximumAge: 0, enableHighAccuracy: true});
        }
    };

    recordPosition = position => {
        if (position.coords.latitude === 0 || position.coords.longitude === 0) {
            notify.show('An unknown error occurred while getting your location.', 'error');
            this.saveSignature();
        }
        else {
            this.setState({latitude: position.coords.latitude, longitude: position.coords.longitude},
                this.saveSignature);
        }
    };

    handleGeolocationError = error => {
        switch (error.code) {
        case error.PERMISSION_DENIED:
            notify.show('You denied the request for Geolocation.', 'error');
            break;
        case error.POSITION_UNAVAILABLE:
            notify.show('Location information is unavailable.', 'error');
            break;
        case error.TIMEOUT:
            notify.show('The request to get user location timed out.', 'error');
            break;
        default:
            notify.show('An unknown error occurred while getting your location.', 'error');
            break;
        }

        this.saveSignature();
    };

    resizeCanvas = () => {
        /*
         * When zoomed out to less than 100%, for some very strange reason,
         * some browsers report devicePixelRatio as less than 1
         * and only part of the canvas is cleared then.
         */
        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        // This part causes the canvas to be cleared
        this.canvas.width = this.canvas.offsetWidth * ratio;
        this.canvas.height = this.canvas.offsetHeight * ratio;
        this.canvas.getContext('2d').scale(ratio, ratio);

        /*
         * This library does not listen for canvas changes, so after the canvas is automatically
         * cleared by the browser, SignaturePad#isEmpty might still return false, even though the
         * canvas looks empty, because the internal data of this library wasn't cleared. To make sure
         * that the state of this library is consistent with visual state of the canvas, you
         * have to clear it manually.
         */
        this.pad.clear();
    };

    sign = e => {
        e.preventDefault();
        this.setState({loading: true}, this.getMyLocation);
    };

    saveSignature = () => {
        if (this.state.fullName.trim().length === 0) {
            notify.show('Please provide a name first.', 'error');
        }
        else if (this.pad.isEmpty()) {
            notify.show('Please provide a signature first.', 'error');
        }
        else {
            const blob = SignatureDialog.dataURLtoBlob(this.canvas.toDataURL());
            const data = new FormData();
            data.append('signature[record_id]', this.props.recordId.toString());
            data.append('signature[record_type]', this.props.recordType);
            data.append('signature[full_name]', this.state.fullName);
            data.append('signature[latitude]', this.state.latitude);
            data.append('signature[longitude]', this.state.longitude);
            data.append('signature[image]', blob, 'signature.png');
            Rails.ajax({
                url: '/signatures',
                type: 'post',
                data,
                success: () => {
                    this.props.callback();
                },
                error: error => {
                    notify.show(error, 'error');
                }
            });
        }
    };

    render() {
        return (
            <div className='reveal' id='signature-dialog'>
                <h2>Sign This Document</h2>

                <div className='info callout'>This signature requires your current location.
                    When prompted, you must allow the application to access your current location.
                </div>

                <form onSubmit={this.sign}>
                    <label htmlFor='full-name'>Full name:</label>
                    <input id='full-name' onChange={e => this.setState({fullName: e.target.value})}
                        required type='text'/>

                    <canvas className='signature-canvas'/>
                    <p className='help-text'>Draw your signatures on the canvas.</p>

                    <div className='grid-x grid-margin-x'>
                        <div className='shrink cell'>
                            <a className='margin-bottom-0 secondary button' data-close=''>
                                <i className='fa fa-times'/>Cancel
                            </a>
                        </div>
                        <div className='shrink cell'>
                            <a className='margin-bottom-0 info button' onClick={() => this.pad.clear()}>
                                <i className='fa fa-eraser'/>Clear
                            </a>
                        </div>
                        <div className='auto cell'>
                            <button className='expanded margin-bottom-0 success button'
                                disabled={this.state.loading} type='submit'>
                                {this.state.loading
                                    ? <span><i className='fa fa-spinner fa-pulse'/>Saving</span>
                                    : 'Sign & Save'}
                            </button>
                        </div>
                    </div>
                </form>

                <DialogCloseButton/>
            </div>
        );
    }
}

SignatureDialog.propTypes = {
    callback: PropTypes.func.isRequired,
    recordId: PropTypes.number.isRequired,
    recordType: PropTypes.string.isRequired
};